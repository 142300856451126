<template>
    <!-- сторінка "Переклади" -->
    <PageHeader 
        :title="title" 
    />

    <div 
        class="row g-4 mb-3"
    >
        <div 
            class="card"
        >
            <div 
                class="card-body"
            >
                <div 
                    class="table-responsive table-card mb-4" 
                    style="margin-top: 1.5rem !important;"
                >
                    <div class="work-panel">
                    
                        <div class="work-panel_selectProject">
                            <select class="form-select" @change="this.objParams.project = $event.target.value; this.getData()">
                                <option v-for="items, i in this.projects" :key="i" :selected="this.objParams.project === items.projectTitle">{{items.projectTitle}}</option>
                            </select>
                        </div>

                        <div class="work-panel_buttons work-panel_buttons-grid">
                            <!-- вызов модалки на добавление -->
                            <button
                                @click="this.showModalAdd = true"
                                class="btn btn-success"
                            >
                                <i class="ri-add-line align-bottom me-1"></i>
                            </button>
                        </div>
                    </div>
                    <tableCustom 
                        :columns="this.columns" 
                        :rows="this.rows"
                        :objParams="this.objParams"
                        :key="this.componentKey"
                        @getData="this.getData"
                    /> 
                    <!-- table component -->
                </div>
            </div>
        </div>
    </div>
    <modalAdd 
        v-if="this.showModalAdd === true" 
        :projects="this.projects"
        :languages="this.langs"
        :objParams="this.objParams"
        @close="this.showModalAdd = false"
        @getData="this.getData"
    ></modalAdd>
</template>

<script>
import tableCustom from './view/table.vue'
import PageHeader from "@/components/page-header";
import modalAdd from "./view/create.vue"
import { TranslatorLocalise } from '@/API_Dash.js'

var apiLocalise = new TranslatorLocalise()

export default {
    data(){
        return {
            showModalAdd: false,
            title: this.$t('translates'),
            rows: [], // keys
            columns: [
                {
                    name: this.$t('key'),
                    value: "key",
                },
                {
                    name: this.mutateName("lang1"),
                    value: "lang1",
                },
                {
                    name: this.mutateName("lang2"),
                    value: "lang2"
                }
            ], // columns for table
            objParams: {
                lang1: 'ru',
                lang2: 'uk',
                project: 'inside-new',
            }, //params for start api
            projects: [],
            langs: [
                {
                value: 'fr',
                title: "Français"
                },
                {
                    "value": "en",
                    "title": "English"
                },
                {
                    "value": "ru",
                    "title": "Русский"
                },
                {
                    "value": "de",
                    "title": "Deutsch"
                },
                {
                    "value": "es",
                    "title": "Español"
                },
                {
                    "value": "pl",
                    "title": "Polski"
                },
                {
                    "value": "uk",
                    "title": "Українська"
                },
                {
                    "value": "it",
                    "title": "Italiano"
                },
                {
                    "value": "he",
                    "title": "עִבְרִית"
                },
                {
                    "value": "tr",
                    "title": "Türkçe"
                },
                {
                    "value": "et",
                    "title": "eesti keel"
                },
                {
                    "value": "be",
                    "title": "Беларуская мова"
                },
                {
                    "value": "ar",
                    "title": "العربية"
                },
                {
                    "value": "fi",
                    "title": "suomi"
                },
                {
                    "value": "lt",
                    "title": "lietuvių kalba"
                },
                {
                    "value": "tg",
                    "title": "Забони тоҷикӣ"
                },
                {
                    "value": "pt",
                    "title": "Português"
                },
                {
                    "value": "ka",
                    "title": "Georgian (Georgia)"
                },
                {
                    "value": "cn",
                    "title": "Chinese Simplified"
                },
                {
                    "value": "el",
                    "title": "Greek"
                },
                {
                    "value": "km",
                    "title": "Khmer (Cambodia)"
                },
                {
                    "value": "lv",
                    "title": "Latvian"
                },
                {
                    "value": "ro",
                    "title": "Romanian"
                },
                {
                    "value": "th",
                    "title": "ภาษาไทย (Thai)"
                }
            ],
            componentKey: 0,
        }
    },
    components: {
        PageHeader,
        tableCustom,
        modalAdd
    },
    created() {
        console.log('languages', this.objParams.lang1, this.objParams.lang2)
        console.log('project', this.objParams.project)
        // default values
        
        this.getProjects() // get projects
        this.getData() //get keys
    },
    mounted() {
        this.columns.forEach((el, index) => {
            if(el.name == undefined) {
                this.columns[index].name = this.mutateName(el.value)
            }
        })
    },
    methods: {
        getProjects() {
            apiLocalise.getProjectsStats().then(result => {
                if(result.status == 'done') {
                    this.projects = result.data
                }
            })
        },
        getData(idx, value) {
            if(idx, value) {
                this.langs.forEach(el => {
                    if(el.title === value) {
                        if(idx === 1) {
                            this.objParams.lang1 = el.value
                        } else {
                            this.objParams.lang2 = el.value
                        }
                    }
                })
            }

            apiLocalise.getTranslationsKeys(this.objParams).then(result => {
                if(result.status == 'done') {
                    this.rows = result.data
                    this.componentKey++
                }
            })
        },
        mutateName(e) {
            if(e === 'lang1') {
                return this.langs?.find(el => el.value === this.objParams.lang1).title // todo dev
            } else if(e === 'lang2'){
                return this.langs?.find(el => el.value === this.objParams.lang2).title // todo dev
            } else {
                throw new Error(this.$toast('error') + ' in mutateName(e)');
            }
        }
    },
}
</script>

<style lang="css" scoped>
    /* panel above table */
    .work-panel {
        display: grid;
        grid-template-columns: 1fr 3fr 1fr;
        grid-template-rows: 1fr;
        margin-bottom: 10px;
    }
    .work-panel_buttons {
        align-self:flex-end;
    }

    .work-panel_buttons-grid{
        display: grid;
        align-items: center;
        justify-content: end;
    }

    .work-panel_buttons > button{
        margin-right: 20px;
        box-shadow: 3px 3px 6px 0px #a5a5a5;
    }
    .work-panel_search{
        padding: 0 10px;
    }
    .work-panel_search > *{
        box-shadow: 3px 3px 6px 0px #a5a5a5;
    }
    .work-panel_selectProject > *{
        box-shadow: 3px 3px 6px 0px #a5a5a5;
    }
</style>