<template>
    <modal>
        <template v-slot:title>{{ this.$t('Add') }}</template>
        <template v-slot:head-bottom>
            <div>
                <button type="button" class="btn btn-soft-danger btn-icon btn-sm fs-16 close-btn-email"  @click="$emit('close')"><i class="ri-close-fill align-bottom"></i></button>
            </div>
        </template>
        <template v-slot:body>
            <div class="row">
                <div class="col-12">
                    <label for="selectProj" style="font-size: 14px;">{{ this.$t('project') }}</label>
                    <select class="form-select" id="selectProject" v-model="this.project">
                        <option v-for="items, i in this.projects" :key="i" :selected="this.objParams.project === items.projectTitle" :value="items.projectTitle">{{items.projectTitle}}</option>
                    </select>
                </div>
            </div>
            <div class="row" style="margin-top: 20px;">
                <div class="col-12">
                    <input type="checkbox" id="selectMode" v-model="this.inJSON" class="form-check-input">
                    <label for="selectMode" class="form-check-label" style="margin-left: 5px; font-size: 14px;">JSON</label>
                </div>
            </div>
            <div class="row" v-if="this.inJSON === true" style="margin-top: 10px;">
                <div class="card">
                    <div class="card-body">
                        <textqe @input="inputText" :objText="answer" />
                    </div>
                </div>
            </div>
            <div class="row" v-else style="margin-top: 10px;">
                <div class="col-6">
                    <label for="key">{{ this.$t('key') }}</label>
                    <input type="text" id="key" class="form-control" v-model="this.obj.key">
                </div>
                <div class="col-6">
                    <label for="translate">{{ this.$t('translate') }}</label>
                    <input type="text" id="translate" class="form-control" v-model="this.obj.translate">
                </div>
            </div> 
        </template>
        <template v-slot:footer-bottom>
            <button class="btn btn-success" @click="this.addKey">{{ this.$t('save') }}</button>
        </template>
    </modal>
</template>

<script>
import textqe from "@/components/textQE"
import modal from '@/components/modal/modal-lg'
import { TranslatorLocalise } from '@/API_Dash.js'

var apiLocalise = new TranslatorLocalise()

export default {
    props:['projects', 'languages', 'objParams'],
    data() {
        return{
            project: '',
            obj: {
                key: '',
                translate: ''
            },
            inJSON: false,
            answer: '<span>{</span><span>&nbsp;&nbsp;&nbsp;&nbsp;"" : ""</span><span>}</span>'
        }
    },
    components: {
        modal,
        textqe
    },
    created(){
        console.log(this)
    },
    methods: {
        inputText(text) {
            this.answer = text.target ? text.target.innerHTML : text;
        },
        addKey() {
            let obj = {translates: {}}

            if(this.inJSON === true) {
                let res = this.answer.replaceAll(/<\/?[^>]+(>|$)/g, "").replaceAll('&nbsp;', '')
                res = res.replaceAll('"', '').replaceAll('{', '').replaceAll('}', '').split(',')
                res.forEach(el => {
                    obj.translates[el.split(' : ')[0]] = el.split(' : ')[1]
                })
                apiLocalise.addTranslate(obj, this.project).then(result => {
                    if(result.status == 'done') {
                        this.$toast.success(this.$t('success'))
                        this.$emit('getData')
                    }
                })
            } else {
                obj.translates[this.obj.key] = this.obj.translate
                apiLocalise.addTranslate(obj, this.project).then(result => {
                    if(result.status == 'done') {
                        this.$toast.success(this.$t('success'))
                        this.$emit('getData')
                    }
                })
            }
        }
    },
}
</script>

<style lang="css" scoped>

</style>