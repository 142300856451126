<template>
    <div class="row" style="margin: 20px 0;">
        <!-- поиск по таблице -->
        <searchBox @searchB="searchB" :typesearch="this.typesearch"/>
    </div>
    <table 
        class="table align-middle table-nowrap mb-0 table-hover"
    >
        <thead 
            class="table-light text-muted"
        >
            <tr>
                <th 
                    scope="col" 
                    v-for="items, i in columns"
                    :key="i"
                >
                    <p class="h6" v-if="i === 0">{{ items.name }}</p>
                    <select v-else class="form-select" style="border: none; background-color: rgba(240, 248, 255, 0);" @change="this.$emit('getData', i, $event.target.value)">
                        <option 
                            v-for="item, k in this.langs" 
                            :key="k" 
                            :selected="i === 1 ? (item.value === this.objParams.lang1) : (item.value === this.objParams.lang2)"
                        >
                            {{item.title}}
                        </option>
                    </select>

                    <span :style="i != 0 ? 'padding: 0.5rem 2.7rem 0.5rem 0.9rem' : ''">
                        <input type="checkbox" :checked="this.searchMode == i+1" @change="this.searchMode = i+1"/>
                    </span>
                </th>
            </tr>
        </thead>
        <tbody>
            <tr 
                v-for="items, i in this.filteredItems" 
                :key="i"
            >
                <td 
                    v-for="item, k in columns" 
                    :key="k"
                >
                    
                    <label 
                        v-if="this.currentIdx !== String(i) + String(k)" 
                        :for="k" 
                        @click="this.currentIdx = String(i) + String(k)"
                    >
                        {{ items[item.value] }}
                    </label>
                    <!-- 
                        Значення стовпчика в рядку 
                    -->
                    
                    <input 
                        v-if="this.currentIdx === String(i) + String(k)" 
                        class="form-control" 
                        type="text" 
                        :id="k" 
                        :spellcheck="k === 0 ? false : true"
                        :value="items[item.value]"
                        @keyup.enter="
                            submitValue({e: k !== 0 ? {key: this.rows[i].key, lang: (k === 1 ? this.objParams.lang1 : this.objParams.lang2), text: $event.target.value} : {oldKey: $event.target._value, newKey: $event.target.value}, row: i, column: k})
                        "
                        @keyup.esc="this.currentIdx = ''"
                        @keyup.delete="this.deleteKey(i)"
                    > 
                    <div 
                        class="control-panel" 
                        v-if="this.currentIdx === String(i) + String(k)" 
                        style="display: grid; 
                        grid-template-columns: repeat(3, 1fr) 7fr; 
                        gap: 10px; margin-top: 10px;"
                    >
                        <button class="btn btn-primary" @click="this.currentIdx = ''">
                            <i class="ri-close-fill align-bottom"></i>
                        </button>
                        <button 
                            class="btn btn-success" 
                            @click="submitValue({e: k !== 0 ? {key: this.rows[i].key, lang: (k === 1 ? this.objParams.lang1 : this.objParams.lang2), text: $event.target.value} : {oldKey: $event.target._value, newKey: $event.target.value}, row: i, column: k})"
                        >
                            <i class='bx bx-save'></i>
                        </button>
                        <button class="btn btn-danger" @click="this.deleteKey(i)">
                            <i class='bx bx-trash' ></i>
                        </button>
                    </div>
                    <!-- 
                        submitValue - об'єднаня функція для двох апі, котра формує об'єкт для двох різних апі. В залежності від значення k:
                            * k !== 0 - дає зрозуміти яке апі будемо юзати
                            * k === 1 || k === 2 - дає зрозуміти переклад якої мови будемо змінювати
                    -->

                    <!-- spellcheck прибирає червоне підкреслення в неправильно написаному слові -->
                    
                    <!-- 
                        Якщо this.currentIdx буде дорівнювати індексу String(i) + String(k) (бля, ну це геніальний "костиль"), 
                        то ми зможемо змінити значення стовпчика в рядку 
                    -->
                </td>
            </tr>
        </tbody>
    </table>
</template>

<script>
import { TranslatorLocalise } from '@/API_Dash.js'
import searchBox from '@/components/globaltable/searchbox.vue';

var apiLocalise = new TranslatorLocalise()

export default {
    data() {
        return {
            typesearch: '',
            search: '',
            currentIdx: '',
            langs: [
                {
                    "value": "en",
                    "title": "English"
                },
                {
                    "value": "ru",
                    "title": "Русский"
                },
                {
                    "value": "de",
                    "title": "Deutsch"
                },
                {
                    "value": "es",
                    "title": "Español"
                },
                {
                    "value": "pl",
                    "title": "Polski"
                },
                {
                    "value": "uk",
                    "title": "Українська"
                },
                {
                    "value": "it",
                    "title": "Italiano"
                },
                {
                    "value": "he",
                    "title": "עִבְרִית"
                },
                {
                    "value": "tr",
                    "title": "Türkçe"
                },
                {
                    "value": "et",
                    "title": "eesti keel"
                },
                {
                    "value": "be",
                    "title": "Беларуская мова"
                },
                {
                    "value": "ar",
                    "title": "العربية"
                },
                {
                    "value": "fi",
                    "title": "suomi"
                },
                {
                    "value": "lt",
                    "title": "lietuvių kalba"
                },
                {
                    "value": "tg",
                    "title": "Забони тоҷикӣ"
                },
                {
                    "value": "pt",
                    "title": "Português"
                },
                {
                    "value": "ka",
                    "title": "Georgian (Georgia)"
                },
                {
                    "value": "cn",
                    "title": "Chinese Simplified"
                },
                {
                    "value": "el",
                    "title": "Greek"
                },
                {
                    "value": "km",
                    "title": "Khmer (Cambodia)"
                },
                {
                    "value": "lv",
                    "title": "Latvian"
                },
                {
                    "value": "ro",
                    "title": "Romanian"
                },
                {
                    "value": "th",
                    "title": "ภาษาไทย (Thai)"
                }
            ],
            interval: '',
            searchMode: 1,
        }
    },
    props: ['columns', 'rows', 'objParams'],
    components: {
        searchBox
    },
    created () {
        this.filteredItems = this.rows
    },
    methods: {
        searchB(e){
            this.typesearch = e
            clearTimeout(this.interval)
            this.interval = setTimeout(() => {
                if(this.typesearch == ''){
                    this.filteredItems = this.rows
                } else if (this.typesearch.indexOf('@') === -1 && this.searchMode == 1) {
                    this.filteredItems = this.rows.filter(el => el.key.indexOf(e) != -1)
                } else if (this.typesearch.indexOf('@') !== -1 && this.searchMode == 1){
                    this.filteredItems = this.rows.find(el => el.key == this.typesearch.replaceAll('@', ''))
                } else if (this.typesearch.indexOf('@') === -1 && this.searchMode == 2) {
                    this.filteredItems = this.rows.filter(el => el.lang1.indexOf(e) != -1)
                } else if (this.typesearch.indexOf('@') !== -1 && this.searchMode == 2){
                    this.filteredItems = this.rows.find(el => el.lang1 == this.typesearch.replaceAll('@', ''))
                } else if (this.typesearch.indexOf('@') === -1 && this.searchMode == 3) {
                    this.filteredItems = this.rows.filter(el => el.lang2.indexOf(e) != -1)
                } else if (this.typesearch.indexOf('@') !== -1 && this.searchMode == 3){
                    this.filteredItems = this.rows.find(el => el.lang2 == this.typesearch.replaceAll('@', ''))
                } 
            }, 777)
        },
        submitValue(obj) {
            if(obj.column === 0) {
                apiLocalise.editKey(obj.e, {project: this.objParams.project}).then(result => {
                    if(result.status === 'done') {
                        this.currentIdx = '' // скидуємо інпути 
                        this.$toast.success(this.$t('done'))
                        this.$emit('getData') // оновлюємо дані
                    }
                })
            } else if(obj.column === 1 || obj.column === 2) {
                apiLocalise.editTranslate(obj.e, {project: this.objParams.project}).then(result => {
                    if(result.status === 'done') {
                        this.currentIdx = '' // скидуємо інпути 
                        this.$toast.success(this.$t('done'))
                        this.$emit('getData') // оновлюємо дані
                    }
                })
            } else {
                throw new Error(this.$toast('error') + ' in submitValue(obj)');
            }
        },
        deleteKey(row){
            apiLocalise.delKey({delKey: this.rows[row].key}, this.objParams.project).then(result => {
                if(result.status === 'done') {
                    this.$toast.success(this.$t('done'))
                    this.$emit('getData') // оновлюємо дані
                }
            })
        }
    },
};
</script>

<style scoped>
    table {
        width: 100% !important; 
        word-break: break-all; 
        table-layout: fixed;
    }
    td{
        word-break: auto-phrase; 
        white-space: unset;
    }
</style>